import * as React from 'react'
import ItemPreview from '../components/itempreview'
import { graphql } from "gatsby"
import * as Styles from './izdeliya.module.sass'
import Seo from '../components/seo'

export const query = graphql`
fragment squareImageCONSTRAINED on File {
  publicURL
  childImageSharp {
    gatsbyImageData(
      layout: CONSTRAINED
      height: 400
      )
  }
}
query {
  bokalL: file(relativePath: { eq: "izdeliya/bokal-l.jpg" }) {
    ...squareImageCONSTRAINED
  }
  rumkaL: file(relativePath: { eq: "izdeliya/rumka-l.jpg" }) {
    ...squareImageCONSTRAINED
  }
  stakanL: file(relativePath: { eq: "izdeliya/stakan-l.jpg" }) {
    ...squareImageCONSTRAINED
  }
  bokalD: file(relativePath: { eq: "izdeliya/bokal-d.jpg" }) {
    ...squareImageCONSTRAINED
  }
  rumkaD: file(relativePath: { eq: "izdeliya/rumka-d.jpg" }) {
    ...squareImageCONSTRAINED
  }
  stakanD: file(relativePath: { eq: "izdeliya/stakan-d.jpg" }) {
    ...squareImageCONSTRAINED
  }
}
`

const IzdeliyaPage = ({ data }) => {
  const links = [
    { url: '/izdeliya/rumka', label: 'Рюмка', sublabel: 'под водку', fileDark: data.rumkaD, fileLight: data.rumkaL },
    { url: '/izdeliya/bokal', label: 'Бокал', sublabel: 'под коньяк', fileDark: data.bokalD, fileLight: data.bokalL },
    { url: '/izdeliya', label: 'Стакан', sublabel: 'под виски', fileDark: data.stakanD, fileLight: data.stakanL, disabled: true }
  ]
  return (
    <>
      <Seo
        title={`Классическая серия`}
      />
      <div>
        <h1 className={'is-size-1 is-size-3-touch mainheader'}>Классическая серия</h1>
        <div className={`columns is-flex is-vcentered mainwrapper ${Styles.mainwrapper}`}>
          <div className={`column pt-1 has-text-centered is-flex is-flex-direction-column ${Styles.item}`}>
            <ItemPreview {...links[0]}></ItemPreview>
          </div>
          <div className={`column pt-1 has-text-centered is-flex is-flex-direction-column ${Styles.item}`}>
            <ItemPreview {...links[1]}></ItemPreview>
          </div>
          <div className={`column pt-1 has-text-centered is-flex is-flex-direction-column ${Styles.item}`}>
            <ItemPreview {...links[2]}></ItemPreview>
          </div>
        </div>
      </div>
    </>
  )
}

export default IzdeliyaPage
