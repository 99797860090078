import * as React from 'react'
import { Link } from 'gatsby'
import Crossfade from '../components/crossfade'
import * as Styles from './itempreview.module.sass'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ItemPreview = ({ ...data }) => {
  return data.disabled ? (<div className={`${Styles.wrapper}`}>
    <div className={`my-4 ${Styles.mobilemargin}`}>
      <Crossfade className={`is-hidden-touch`} alt={data.label} fileDark={data.fileDark} fileLight={data.fileDark}></Crossfade>
      <GatsbyImage className={`is-hidden-desktop`} alt={data.label} image={getImage(data.fileDark)} />
    </div>
    <div className={`mt-6 ${Styles.mobilemargin}`}><p className={`${Styles.label} has-text-primary-dark text-primary`}><span className={`is-size-3`}>{data.label}&nbsp;{data.sublabel}</span></p></div>
    <div className={`my-4 ${Styles.mobilemargin}`}><span disabled className={`${Styles.disabled} button is-rounded is-primary is-outlined is-small`}>Скоро</span></div>
  </div>
  ) : (<div className={`${Styles.wrapper} parentcrossfade`}>
    <div className={`my-4 ${Styles.mobilemargin}`}>
      <Link to={data.url}>
        <Crossfade className={`is-hidden-touch`} alt={data.label} fileDark={data.fileDark} fileLight={data.fileLight}></Crossfade>
        <GatsbyImage className={`is-hidden-desktop`} alt={data.label} image={getImage(data.fileLight)} />
      </Link>
    </div>
    <div className={`mt-6 ${Styles.mobilemargin}`}><Link to={data.url} ><p className={Styles.label}><span className={`is-size-3`}>{data.label}&nbsp;{data.sublabel}</span></p></Link></div>
    <div className={`my-4 ${Styles.mobilemargin}`}><Link to={data.url} className={`${Styles.button} button is-rounded is-primary is-outlined is-small`}><span>Подробней</span></Link></div>
  </div>)
}

export default ItemPreview
