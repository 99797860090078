import * as React from 'react'
import * as Styles from './crossfade.module.sass'

const Crossfade = ({ ...props }) => {
  return (
    <div className={Styles.crossfade + " " + props.className}>
      <img src={props.fileDark.publicURL} alt={props.alt} />
      <img className={Styles.crossfadeTop} src={props.fileLight.publicURL} alt={props.alt} />
    </div>)
}
export default Crossfade
